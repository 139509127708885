import Vue from "vue";
import Vuex from "vuex";
import api from "@/api";
import vm from "@/main";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {
      userNo: "",
      name: "",
      mobile: "",
      status: "", // 1-审核通过 2-审核拒绝 3-待审核
      isActive: false, // 是否新用户
      isLogin: false, // 是否登录
      investigateId: "", // 调查表id
    },
  },
  getters: {},
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = {
        ...state.userInfo,
        ...payload,
      };
    },
  },
  actions: {
    async getCustomerDetail({ commit }, payload = {}) {
      const res = await api.getCustomerDetail(payload.noAuth);
      commit("setUserInfo", {
        ...res.result,
        isLogin: true,
      });
    },
    async logout({ commit, state }) {
      await api.logout({
        userNo: state.userInfo.mobile,
        token: localStorage.getItem("userToken"),
        userType: "20000",
      });
      localStorage.removeItem("userToken");
      commit("setUserInfo", { isLogin: false });
      vm.$router.push("/login");
    },
  },
  modules: {},
});
