import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/style/reset.less";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";

Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false;
ElementUI.Table.props.headerCellStyle = {
  type: [Object, Function],
  default: () => {
    return {
      backgroundColor: "#f0f0f0",
      color: "#333",
    };
  },
};
Vue.use(ElementUI);
const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vm;
