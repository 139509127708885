import axios from "axios";
import Vue from "vue";
import vm from "../main";
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 30000,
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    if (config.method === "get") {
      config.data = { ...config.data, t: Date.now() };
    }
    if (localStorage.getItem("userToken")) {
      config.headers.token = localStorage.getItem("userToken");
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    if (response.data.errCode === 1000) {
      return Promise.resolve(response.data);
    } else {
      Vue.prototype.$message.error(response.data.errMsg);
    }
    return Promise.reject(response.data);
  },
  function (error) {
    if (error.status === 303) {
      vm.$store.commit("setUserInfo", { isLogin: false });
      if (!error.noAuth) {
        vm.$router.push("/login");
      }
      return;
    }
    if (JSON.stringify(error).indexOf("Network Error")) {
      Vue.prototype.$message.error("网络请求异常");
    }
    return Promise.reject(error);
  }
);

export default instance;
